import React from 'react'
import { FiImage } from 'react-icons/fi'
import { SERVICE_TYPES_API } from '../../../Utilities/APIs'
import NCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD'

function ServiceTypes() {
  return (
    <NCRUD
      // common props
      api={SERVICE_TYPES_API}
      screenTopicSingular='Service Type'
      screenTopicPlural='Service Types'


      // crud header props
      showTotalInHeading


      // crud props
      allowCreate
      allowUpdate
      
      // action button props
      extraActionButton
      extraActionButtonHandleClick='/services'
      extraActionButtonChildren={<FiImage />}
     


    />
  )
}

export default ServiceTypes