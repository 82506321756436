import React from 'react'
import {  SISTER_CONCERNS_API, SISTER_CONCERN_TYPES_API } from '../../../Utilities/APIs'
import PcDINSCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINSCRUD/PcDINSCRUD'

function SisterConcerns() {
  return (
    <PcDINSCRUD
    // parentChild props
    parentDBField='sisterConcernType'


    // common props
    api={SISTER_CONCERNS_API}
    screenTopicSingular='Sister Concern'
    screenTopicPlural='Sister Concerns'


    // crud header props
    showTotalInHeading


    // crud props
    allowCreate
    allowUpdate


    // select props
    selectApi={SISTER_CONCERN_TYPES_API}
    selectType='parent-child'
    selectDBField='sisterConcernType'
    selectName='Sister Concern Type'
/>
  )
}

export default SisterConcerns