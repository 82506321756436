import React from 'react'
import { FiImage } from 'react-icons/fi'
import { SISTER_CONCERN_TYPES_API } from '../../../Utilities/APIs'
import NCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD'

function SisterConcernTypes() {
    return (
        <NCRUD
            // common props
            api ={SISTER_CONCERN_TYPES_API}
            screenTopicSingular='Sister Concern Type'
            screenTopicPlural='Sister Concern Types'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            
            extraActionButton
            extraActionButtonHandleClick='/sisterConcerns'
            extraActionButtonChildren={<FiImage />}

        />
    )
}

export default SisterConcernTypes