import React from 'react'
import { MISSION_API } from '../../../Utilities/APIs'
import DNCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNCRUD/DNCRUD'

function Missions() {
    return (
        <DNCRUD
            // common props
            api={MISSION_API}
            screenTopicSingular='Mission'
            screenTopicPlural='Missions'


            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
        />
    )
}

export default Missions