import { RiChatQuoteLine, RiContactsBook2Line, RiLinksLine, RiSettings2Fill } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function SettingsScreen() {
    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'App Settings'} navCardLink={'/appSettings'} ><RiSettings2Fill /></NavCard>
            <NavCard cardName={'Testimonials'} navCardLink={'/testimonials'} ><RiChatQuoteLine /></NavCard>
            <NavCard cardName={'Necessary Links'} navCardLink={'/necessaryLinks'} ><RiLinksLine /></NavCard>
            <NavCard cardName={'Contact Infos'} navCardLink={'/contactInfos'} ><RiContactsBook2Line /></NavCard>
        </NavCardList>
    )
}

export default SettingsScreen