import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';

import { RiAncientPavilionLine, RiGovernmentLine, RiHome2Line, RiServiceLine, RiSettings2Line } from 'react-icons/ri';

import './SideBar.css';

function SideBar() {

    return (
        <>
            <section className="small_sidebar">
                <div className='container'>

                    {/* <SmallSideBarItem
                        link='/'
                        dataTip='Dashboard'
                        dataFor='dashboard'
                    >
                        <RiHome2Line />
                    </SmallSideBarItem> */}

                    <SmallSideBarItem
                        link='/homeScreen'
                        dataTip='Home'
                        dataFor='Home'
                    >
                        <RiHome2Line />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/sisterConcernScreen'
                        dataTip='Sister Concern'
                        dataFor='Sister Concern'
                    >
                        <RiAncientPavilionLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/serviceScreen'
                        dataTip='Services'
                        dataFor='Services'
                    >
                        <RiServiceLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem
                        link='/companyOverviewScreen'
                        dataTip='Company Overview'
                        dataFor='Company Overview'
                    >
                        <RiGovernmentLine/>
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/settingsScreen'
                        dataTip='Settings'
                        dataFor='settings'
                    >
                        <RiSettings2Line />
                    </SmallSideBarItem>
                </div>
            </section>
        </>
    )
}

export default SideBar
