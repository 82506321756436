import { useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { CONTACTS_API} from "../../../Utilities/APIs"
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import EmailInput from "../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"


function CreateContactInfos({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [map, setMap] = useState('')
 
 

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
       const contactInfos = { name, phone, email, address, map  }
   
      console.log(contactInfos);
        const response = await axios.post(CONTACTS_API , contactInfos)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <NumberInput
                label={`Phone`}
                value={phone}
                placeholder={`Enter Phone`}
                setState={setPhone}
            />
            
            <EmailInput
                label={`Email`}
                value={email}
                placeholder={`Enter Email`}
                setState={setEmail}
            />
             <ShortTextInput
                label={`Address`}
                value={address}
                placeholder={`Enter Address`}
                setState={setAddress}
            />
             <ShortTextInput
                label={`Map`}
                value={map}
                placeholder={`Enter Map`}
                setState={setMap}
            />

            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Create Client' />
        </Form>
    )
}

export default CreateContactInfos