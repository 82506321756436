import React from 'react'
import { RiBook2Fill, RiMedal2Fill, RiPenNibFill, RiProfileLine, RiStarFill, RiSunFill } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function CompanyOverviewScreen() {
  return (
    <NavCardList numOfCards={'six'}>
    <NavCard cardName={'Company Overviews'} navCardLink='/companyOverviews' ><RiProfileLine /></NavCard>
    <NavCard cardName={'Missions'} navCardLink='/missions' ><RiMedal2Fill /></NavCard>
    <NavCard cardName={'Vision'} navCardLink='/vissions' ><RiSunFill /></NavCard>
    <NavCard cardName={'Careers'} navCardLink='/careers' ><RiPenNibFill /></NavCard>
    <NavCard cardName={'Clients'} navCardLink='/clients' ><RiStarFill /></NavCard>
    <NavCard cardName={'About'} navCardLink='/abouts' ><RiBook2Fill /></NavCard>

  </NavCardList>
  )
}

export default CompanyOverviewScreen