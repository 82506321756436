import React from 'react'
import { SERVICES_API, SERVICE_TYPES_API } from '../../../Utilities/APIs'
import PcDINSCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINSCRUD/PcDINSCRUD'

function Services() {
    return (
        <PcDINSCRUD
            // parentChild props
            parentDBField='serviceType'


            // common props
            api={SERVICES_API}
            screenTopicSingular='Service'
            screenTopicPlural='Services'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate

            // select props
            selectApi={SERVICE_TYPES_API}
            selectType='parent-child'
            selectDBField='serviceType'
            selectName='Service Type'
        />
    )
}

export default Services