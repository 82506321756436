import React from 'react'
import { ABOUTS_API } from '../../../Utilities/APIs'
import DCRUD from '../../Partials/Layouts/CRUDs/DCRUD/DCRUD'

function Abouts() {
    return (
        <DCRUD
            // common props
            api={ABOUTS_API}
            screenTopicSingular='About'
            screenTopicPlural='Abouts'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate

        />
    )
}

export default Abouts