import React from 'react'
import { ABOUT_CORES_API } from '../../../Utilities/APIs'
import DINCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DINCRUD/DINCRUD'

function AboutCores() {
    return (
        <DINCRUD
            // common props
            api={ABOUT_CORES_API}
            screenTopicSingular='About Core'
            screenTopicPlural='About Cores'


            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            allowDelete

        />
    )
}

export default AboutCores