import React from 'react'
import { TESTIMONIALS_API } from '../../../Utilities/APIs'
import DNPTCRUD from '../../Partials/Layouts/CRUDs/DNPTCRUD/DNPTCRUD'

function Testimonials() {
    return (
        <DNPTCRUD
            // common props
            api={TESTIMONIALS_API}
            screenTopicSingular='Testimonial'
            screenTopicPlural='Testimonials'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate

            //text props
            maxPrecedence={5}
            textDBField='designation'
            textName='designation'

        />
    )
}

export default Testimonials