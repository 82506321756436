import { useState, useEffect } from "react"
import axios from "axios"
import { CONTACTS_API } from "../../../Utilities/APIs"


function ViewContactInfos({

    //common props
    targetID, employee

}) {

    const [info, setInfos] = useState(null)
    console.log(info)
    useEffect(() => {

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }

        async function fetchAndSetInfo() {

            const { data } = await axios.get(CONTACTS_API  + targetID)
            setInfos(data);
        }
        fetchAndSetInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {info &&
                <>
                    <h1>Name</h1>
                    <p>{info.name}</p>
                    <h1>Phone</h1>
                    <p>{info.phone}</p>
                    <h1>Email</h1>
                    <p>{info.email}</p>
                    <h1>Address</h1>
                    <p>{info.address}</p>
                    <h1>Map</h1>
                    <p>{info.map}</p>
                    
                  
                </>
            }
        </div>
    )
}

export default ViewContactInfos