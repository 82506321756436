import React from 'react'
import { CAREERS_API } from '../../../Utilities/APIs'
import DNCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNCRUD/DNCRUD'


function Careers() {
  return (
    <DNCRUD
    // common props
    api={CAREERS_API}
    screenTopicSingular='Career'
    screenTopicPlural='Careers'


    // crud header props
    showTotalInHeading

    // crud props
    allowCreate
    allowUpdate
/> 
  )
}

export default Careers