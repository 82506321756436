import React from 'react'
import { NECESSARY_LINKS_API } from '../../../Utilities/APIs'
import NTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/NTCRUD/NTCRUD'

function NecessaryLinks() {
    return (
        <NTCRUD
            // common props
            api={NECESSARY_LINKS_API}
            screenTopicSingular='Necessary Link'
            screenTopicPlural='Necessary Links'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            
            // field props
            //text props
            textDBField='link'
            textName='Link'

        />
    )
}

export default NecessaryLinks