import React from 'react'
import { RiAncientPavilionFill } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function SisterConcernScreen() {
  return (
    <NavCardList numOfCards={'four'}>
    <NavCard cardName={'Sister Concern Types'} navCardLink='/sisterConcernTypes' ><RiAncientPavilionFill /></NavCard>

  </NavCardList>
  )
}

export default SisterConcernScreen