import { useEffect, useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { CONTACTS_API } from "../../../Utilities/APIs"
import EmailInput from "../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"

// import { CLIENTS_API } from '../../../../Utilities/APIs'
// import Form from '../../../Partials/Layouts/Forms/Form'
// import ImageInput from '../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput'
// import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
// import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'

function UpdateContactInfo({

    employee, setShowUpdateForm, setShowModal, triggerFetch, targetID

}) {

   
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [map, setMap] = useState('')
 
    useEffect(() => {

        async function fetchAndSetContectInfo() {

            const { data } = await axios.get(CONTACTS_API  + targetID)
            setName(data.name);
            setPhone(data.phone);
            setEmail(data.email);
            setAddress(data.address);
            setMap(data.map);
        }
        fetchAndSetContectInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const contactInfos = { name, phone, email, address, map  }
   
      console.log(contactInfos);
        const response = await axios.patch(CONTACTS_API  + targetID , contactInfos )

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
             <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <NumberInput
                label={`Phone`}
                value={phone}
                placeholder={`Enter Phone`}
                setState={setPhone}
            />
            
            <EmailInput
                label={`Email`}
                value={email}
                placeholder={`Enter Email`}
                setState={setEmail}
            />
             <ShortTextInput
                label={`Address`}
                value={address}
                placeholder={`Enter Address`}
                setState={setAddress}
            />
             <ShortTextInput
                label={`Map`}
                value={map}
                placeholder={`Enter Map`}
                setState={setMap}
            />

            <FormSubmitButton text='Create Client' />
        </Form>
    )
}

export default UpdateContactInfo