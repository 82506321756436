import React from 'react'
import { COMPANY_OVERVIEWS_API } from '../../../Utilities/APIs'
import DNCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNCRUD/DNCRUD'

function CompanyOverviews() {
    return (
        <DNCRUD
            // common props
            api={COMPANY_OVERVIEWS_API}
            screenTopicSingular='Company Overview'
            screenTopicPlural='Company Overviews'


            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
        />
    )
}

export default CompanyOverviews