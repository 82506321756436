import React from 'react'
import { RiProfileLine } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function ServiceScreen() {
  return (
    <NavCardList numOfCards={'four'}>
    <NavCard cardName={'Service Types'} navCardLink='/serviceTypes' ><RiProfileLine /></NavCard>

  </NavCardList>
  )
}

export default ServiceScreen