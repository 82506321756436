import React from 'react'
import { CORE_VALUES_API } from '../../../Utilities/APIs'
import INCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/INCRUD/INCRUD'


function CoreValues() {
    return (
        <INCRUD
            // common props
            api={CORE_VALUES_API}
            screenTopicSingular='Core Value'
            screenTopicPlural='Core Values'


            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate


        />
    )
}

export default CoreValues