import React from 'react'
import { SLIDER_API } from '../../../Utilities/APIs'
import INPTCRUPD from '../../Partials/Layouts/CRUDs/INPTCRUPD/INPTCRUPD'

function Slider() {
    return (
        <INPTCRUPD
            // common props
            api={SLIDER_API}
            screenTopicSingular='Title'
            screenTopicPlural='Sliders'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete


            //text props
            maxPrecedence={5}
            textDBField='subTitle'
            textName='SubTitle'

        />
    )
}

export default Slider