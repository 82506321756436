import {Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../Redux/Features/Auth/authSlice'

import './App.css'

import NavBar from './Partials/Sections/NavBar/NavBar'
import SideBar from './Partials/Sections/SideBar/SideBar'
import Login from './Screens/AuthScreens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees'
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen'
import Employees from './Screens/AuthScreens/Employees/Employees'
import AppSettings from './Screens/AppSettings/AppSettings'
import Register from './Screens/AuthScreens/Register/Register'
import SettingsScreen from './Screens/Settings/SettingsScreen'
import OrderSettings from './Screens/Settings/OrderSettings'
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne'
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree'
import AboutCores from './Screens/AboutCores/AboutCores'
import SisterConcernTypes from './Screens/SisterConcernScreen/SisterConcernTypes'
import SisterConcernScreen from './Screens/SisterConcernScreen/SisterConcernScreen'
import ServiceScreen from './Screens/ServiceScreen/ServiceScreen'
import ServiceTypes from './Screens/ServiceScreen/ServiceTypes'
import CompanyOverviewScreen from './Screens/CompanyOverviewScreen/CompanyOverviewScreen'
import CompanyOverviews from './Screens/CompanyOverviewScreen/CompanyOverviews'
import Missions from './Screens/CompanyOverviewScreen/Missions'
import Vissions from './Screens/CompanyOverviewScreen/Vissions'
import Careers from './Screens/CompanyOverviewScreen/Careers'
import HomeScreen from './Screens/HomeScreen/HomeScreen'
import CoreValues from './Screens/HomeScreen/CoreValues'
import Clients from './Screens/CompanyOverviewScreen/Clients'
import Testimonials from './Screens/Testimonials/Testimonials'
import Abouts from './Screens/CompanyOverviewScreen/Abouts'
import Slider from './Screens/HomeScreen/Slider'
import NecessaryLinks from './Screens/NecessaryLinks/NecessaryLinks'
import ContactInfosScreen from './Screens/ContactInfosScreen/ContactInfosScreen'
import Services from './Screens/ServiceScreen/Services'
import SisterConcerns from './Screens/SisterConcernScreen/SisterConcerns'



function App() {

  const {employee} = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  axios.interceptors.response.use(
    (res) => {
       return res;
    },
    (err) => {
      if (err.response.status === 401) {
        dispatch(logout())
      }
       return Promise.reject(err);
    }
  );

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className='screen-container'>

            <Routes>

              <Route path='/login' element={!employee ? <Login /> : <Navigate to={'/'} />} />
              <Route path='/register/:token' element={!employee ? <Register /> : <Navigate to={'/'} />} />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route path="/passwordReset/:token" element={<RecoverPassThree />} />
              
              <Route path='/employeeScreens' element={employee ? <EmployeesScreen /> : <Navigate to={'/login'} />} />
              <Route path='/employeeInvites' element={employee ? <InviteEmployees /> : <Navigate to={'/login'} />} />
              <Route path='/employees' element={employee ? <Employees /> : <Navigate to={'/login'} />} />

              <Route path='/' element={employee ? <Dashboard /> : <Navigate to={'/login'} />} />
              <Route path='/homeScreen' element={employee ? <HomeScreen /> : <Navigate to={'/login'} />} />
              <Route path='/sliders' element={employee ? <Slider /> : <Navigate to={'/login'} />} />
              <Route path='/coreValues' element={employee ? <CoreValues /> : <Navigate to={'/login'} />} />
             
              <Route path='/aboutCores' element={employee ? <AboutCores /> : <Navigate to={'/login'} />} />
             
              <Route path='/sisterConcernScreen' element={employee ? <SisterConcernScreen /> : <Navigate to={'/login'} />} />
              <Route path='/sisterConcernTypes' element={employee ? <SisterConcernTypes /> : <Navigate to={'/login'} />} />
              <Route path='/sisterConcerns/:parentID' element={employee ? <SisterConcerns /> : <Navigate to={'/login'} />} />
              
              
              <Route path='/serviceScreen' element={employee ? <ServiceScreen /> : <Navigate to={'/login'} />} />
              <Route path='/serviceTypes' element={employee ? <ServiceTypes /> : <Navigate to={'/login'} />} />
              <Route path='/services/:parentID' element={employee ? <Services /> : <Navigate to={'/login'} />} />
              
              <Route path='/companyOverviewScreen' element={employee ? <CompanyOverviewScreen /> : <Navigate to={'/login'} />} />
              <Route path='/companyOverviews' element={employee ? <CompanyOverviews /> : <Navigate to={'/login'} />} />
              <Route path='/missions' element={employee ? <Missions /> : <Navigate to={'/login'} />} />
              <Route path='/vissions' element={employee ? <Vissions /> : <Navigate to={'/login'} />} />
              <Route path='/careers' element={employee ? <Careers /> : <Navigate to={'/login'} />} />
              <Route path='/clients' element={employee ? <Clients /> : <Navigate to={'/login'} />} />
              <Route path='/abouts' element={employee ? <Abouts /> : <Navigate to={'/login'} />} />
 

              <Route path='/settingsScreen' element={employee ? <SettingsScreen /> : <Navigate to={'/login'} />} />
              <Route path='/appSettings' element={employee ? <AppSettings /> : <Navigate to={'/login'} />} />
              <Route path='/testimonials' element={employee ? <Testimonials /> : <Navigate to={'/login'} />} />
              <Route path='/necessaryLinks' element={employee ? <NecessaryLinks /> : <Navigate to={'/login'} />} />
              <Route path='/contactInfos' element={employee ? <ContactInfosScreen /> : <Navigate to={'/login'} />} />
              
              <Route path='/orderSettings' element={employee ? <OrderSettings /> : <Navigate to={'/login'} />} />
           
            </Routes>
            
          </section>
        </main>
      </BrowserRouter>
    </section>
  )
}

export default App
