import React from 'react'
import { RiCoreosLine, RiFileHistoryLine, RiImageLine } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function HomeScreen() {
  return (
    <NavCardList numOfCards={'six'}>
      <NavCard cardName={'Sliders'} navCardLink='/sliders' ><RiImageLine /></NavCard>
      <NavCard cardName={'Core Values'} navCardLink='/coreValues' ><RiCoreosLine /></NavCard>
      <NavCard cardName={'About Cores'} navCardLink='/aboutCores' ><RiFileHistoryLine /></NavCard>
     
    </NavCardList>
  )
}

export default HomeScreen