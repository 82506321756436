import React from 'react'
import { VISSION_API } from '../../../Utilities/APIs'
import DNCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DNCRUD/DNCRUD'

function Vissions() {
  return (
    <DNCRUD
    // common props
    api={VISSION_API}
    screenTopicSingular='Vision'
    screenTopicPlural='Visions'


    // crud header props
    showTotalInHeading

    // crud props
    allowCreate
    allowUpdate
/>
  )
}

export default Vissions