import React from 'react'
import { CLIENTS_API } from '../../../Utilities/APIs'
import ICRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/ICRUD/ICRUD'

function Clients() {
    return (
        <ICRUD
            // common props
            api={CLIENTS_API}
            screenTopicSingular
            screenTopicPlural


            // crud header props
            showTotalInHeading
            

            // crud props
            allowCreate
            allowUpdate


        />
    )
}

export default Clients